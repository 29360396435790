<script>
  import { onMount } from "svelte";
  export let date;

  onMount(async () => {
    const res = await fetch("/api/date");
    const newDate = await res.text();
    date = newDate;
  });
</script>

<main>
  <h1>Gramrphone Downloads</h1>
  <h2>OSX Audio Units</h2>
  <p>
    <span>v0.1.3</span>
    <a href="downloads/GramrphoneLivePluginInstaller.v0.1.3.pkg" target="download">
      Download  
    </a>
  </p>
  
</main>
